.carousel-control-next-icon {
    width: 2rem/* 32px */;
    height: 3rem/* 32px */;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url("../../Assets/leftarrow2.webp");
}
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
}
.carousel-control-prev-icon {
    width: 2rem/* 32px */;
    height: 3rem/* 32px */;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url("../../Assets/rightarrow1.png");
}
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
}

