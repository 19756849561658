.body {
  color: #3c3f64;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 5px;
  margin: 0 auto;
  padding-top: 50px;
  background: green;
}
.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) rotate(45deg);
  width: 25px;
  height: 25px;
  z-index: 2;
  background: green;
}
.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 360px;
  padding-top: 45px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px);
  border: 3px solid rgba(255, 255, 255, 0.5);
  align-items: center;
}
.timeline ul li div time {
  position: absolute;
  background: #22c55e;
  width: 350px;
  height: 60px;
  top: -15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.timeline ul li div div {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeline ul li div div p {
  text-align: center;
}
.timeline ul li div .discovery {
  margin-right: 10px;
}
.timeline ul li:nth-of-type(odd) > div {
  left: 45px;
}
.timeline ul li:nth-of-type(even) > div {
  left: -439px;
}

.timeline ul li div {
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.5s ease-in;
}
/* .timeline ul li:nth-of-type(odd) div {
  transform: translate3d(100px, -10px, 0);
}
.timeline ul li:nth-of-type(even) div {
  transform: translate3d(-100px, -10px, 0);
} */
.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
    flex-direction: column;
  }
  .timeline ul li div div {
    width: 80%;
    margin: 10px;
  }
  .timeline ul li:nth-of-type(even) > div {
    left: -289px;
  }
}
@media screen and (max-width:600px){
    .body{
        background: #fff;
    }
    .timeline ul li{
        margin-left: 20px;

    }
    .timeline ul li div{
        width: calc(100vw - 91px);
    }
    .timeline ul li:nth-of-type(even) > div{
        left: 45px;
    }
    .timeline ul li div time{
      width: 250px;
    }
   
}

