/* mobile navabar css  */

.hamburger {
  cursor: pointer;
  width: 16px;
  height: 1px;
  transition: all 0.25s;
  position: relative;
}

.hamtop,
.hammiddle,
.hambottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 1px;
  background: black;
  transform: rotate(0);
  transition: all 0.5s;
}

.hammiddle {
  transform: translateY(7px);
}
.hambottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90 deg);
  transform: translateY(0px);
}

.open .hamtop {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hammiddle {
  display: none;
}

.open .hambottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
