
.carousel-control-next-icon1 {
    width: 2rem/* 32px */;
    height: 3rem/* 32px */;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url("../../Assets/rightArrow.png");
}
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
}
.carousel-control-prev-icon1 {
    width: 2rem/* 32px */;
    height: 3rem/* 32px */;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url("../../Assets/leftArrow.png");
}
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
}

.slide_direction {
    display: flex;
    justify-content: space-between;
  }

