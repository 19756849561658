.card-container:hover {
    animation-name: grow;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
  
  /* .card-container:active {
    transform: scale(.99);
    box-shadow: 3px 3px 3px rgba(0,0,0,0.19);
  }
   */
  @keyframes grow {
   
    100% {
      transform: scale(1.03);
      box-shadow: 10px 10px 10px rgba(0,0,0,0.19);
    }
  }