.TopIndexing {
  z-index: 9999;
}


/* Define custom animation */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation to the modal */
.slideInFromBottom {
  animation: slideInFromBottom 0.5s ease-out;
}
