.event {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  display: block;
  /* margin: 0 0; */
  /* width: 300px; */
}

.event .content {
  background: rgba(8, 8, 8, 0.8);
  color: #fff;
  bottom: 0;
  height: 45px;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}

/* .event h3 {
  font: bold 1em/1.125em "Montserrat", Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  height: 40px;
  margin: 0;
  padding-top: 10px;
  text-transform: uppercase;
} */

.event .rollover {
  border-top: 1px solid #73c773;
  font-size: 0.9375em;
  line-height: 1.4em;
  padding: 18px 20px;
  opacity: 0;
  transition: all 0.5s ease;
}

.event .rollover p {
  margin: 0;

}

.event:hover .content {
  background: rgba(8, 8, 8, 0.8);
  height: 32%;
  opacity: 1;
}

.event:hover .rollover {
  opacity: 1;
}

/* @media only screen and (max-width: 768px) {
  body {
    background-color: lightblue;
  }
} */